<template>
  <div class="billreport">
    <div class="title">
      <h2>Reject Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :model="rejectReport" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="rejectReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="OfferName" label-width="2rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="rejectReport.offerName"
                      placeholder="OfferName: eg. Mobile Strike"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Affiliate(s)</label>
                </el-col>
                <el-form-item label="AffiliateId(s)" label-width="2.08rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="rejectReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      filterable
                      reserve-keyword
                      placeholder="请选择"
                      style="width:10.14rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Source(s)</label>
                </el-col>
                <el-form-item label="Source(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="12">
                    <group-select
                      v-model="rejectReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:3.95rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="SourceOfferId" label-width="2rem">
                  <el-col :xs="22" :sm="12" :md="12" :lg="6" :xl="12">
                    <el-input
                      v-model="rejectReport.sourceOfferIds"
                      placeholder="source offer id here"
                      style="width:2.82rem;"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceOfferIdChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Aff_sub(s)</label>
                </el-col>
                <el-form-item label="Aff_Sub" label-width="1.64rem">
                  <el-col :lg="20" :md="20" :xs="24" :sm="20" :xl="20">
                    <el-input
                      v-model="rejectReport.affSubs"
                      style="width:10.64rem;"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="affSubsChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Target(s)</label>
                </el-col>
                <el-form-item label="Countries" label-width="1.8rem">
                  <el-col :lg="6" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="rejectReport.countries"
                      style="width:2.4rem;"
                      placeholder="eg. US,IT"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="Package" label-width="1.2rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="rejectReport.pkgList"
                      style="width:2.5rem;"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="rejectReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.number"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="8" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="exportExcelFileClick">
                      Download Excel
                    </el-button>
                    <el-button type="primary" @click="exportDetailClick">Download Detail</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              ref="tableRef"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="rejectReportList"
              highlight-current-row
            >
              <el-table-column
                label="Date"
                prop="date"
                fixed="left"
                align="center"
                width="170"
                key="date"
              ></el-table-column>
              <el-table-column
                label="Affiliate ID"
                :fixed="fullWidth <= 768 ? false : 'left'"
                min-width="100"
                align="center"
                v-if="affiliateChecked"
                key="affiliateId"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                    scope.row.affiliateId
                  }}</span>
                  <el-tag
                    v-if="scope.row.affiliateTrafficTag"
                    :color="scope.row.affColor"
                    style="color:white;height:24px;line-height:24px;"
                  >
                    {{ scope.row.affiliateTrafficTag }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Affiliate Name"
                :fixed="fullWidth <= 768 ? false : 'left'"
                width="140"
                align="center"
                v-if="affiliateChecked"
                key="affiliateName"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                    scope.row.affiliateName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Source ID"
                v-if="sourceChecked"
                width="100"
                align="center"
                key="sourceId"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                    scope.row.sourceId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Source Name"
                width="140"
                align="center"
                v-if="sourceChecked"
                key="sourceName"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                    scope.row.sourceName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Aff Sub"
                prop="affSub"
                width="150"
                align="center"
                v-if="affSubsChecked"
                key="affSub"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.affSub }}</span>
                  <el-tag
                    v-if="scope.row.affSubTrafficTag && scope.row.affSub"
                    :color="scope.row.affSubColor"
                    style="color:white;height:24px;line-height:24px;"
                  >
                    {{ scope.row.affSubTrafficTag }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Offer ID"
                align="center"
                v-if="offerChecked"
                width="110"
                key="offerId"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                    scope.row.offerId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Offer Name"
                v-if="offerChecked"
                width="140"
                align="center"
                show-overflow-tooltip
                key="offerName"
              >
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                    scope.row.offerName
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Offer Status"
                prop="offerStatus"
                width="130"
                align="center"
                v-if="offerChecked"
                key="offerStatus"
              ></el-table-column>
              <el-table-column
                label="Reject Reason"
                prop="rejectReason"
                width="200"
                align="center"
              ></el-table-column>
              <!--              TODO: 暂时不显示-->
              <!--              <el-table-column-->
              <!--                label="Reject Value"-->
              <!--                prop="rejectValue"-->
              <!--                width="200"-->
              <!--                align="center"-->
              <!--              ></el-table-column>-->
              <el-table-column
                label="Prod Name"
                prop="pkg"
                width="120"
                align="center"
                v-if="packageChecked"
                key="pkg"
              ></el-table-column>
              <el-table-column
                label="Country"
                prop="country"
                width="100"
                align="center"
                v-if="countriesChecked"
                key="country"
              ></el-table-column>
              <el-table-column
                label="Conversions"
                min-width="110"
                prop="conversions"
                align="center"
                key="conversions"
              >
                <template slot-scope="scope">
                  <span
                    v-html="
                      showReportComparingItem(
                        filterNull(scope.row.conversions),
                        new Number(filterNull(scope.row.lastConversions)).toFixed(2)
                      )
                    "
                  ></span>
                </template>
              </el-table-column>
              <el-table-column
                label="Conversions(拒绝转化数)"
                align="center"
                min-width="180"
                prop="affConversions"
                key="affConversions"
              >
                <template slot-scope="scope">
                  <span
                    v-html="
                      showReportComparingItem(
                        filterNull(scope.row.affConversions),
                        new Number(filterNull(scope.row.lastAffConversions)).toFixed(2)
                      )
                    "
                  ></span>
                </template>
              </el-table-column>
              <el-table-column
                label="RejectRate"
                align="center"
                min-width="140"
                prop="rejectRate"
                key="rejectRate"
              >
                <template slot-scope="scope">
                  <span>{{ getRejectRate(scope.row.affConversions, scope.row.conversions) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="SourceOfferId"
                v-if="sourceOfferIdChecked"
                width="120"
                align="center"
                key="sourceOfferId"
              >
                <template slot-scope="scope">
                  <span>{{ filterNull(scope.row.sourceOfferId) }}</span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageParam.pageSize"
              :currentPage="pageParam.page"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { api, getRejectReport } from 'api/report';

  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import { mapActions, mapState } from 'vuex';
  import qs from 'querystring';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        affiliateChecked: true,
        offerChecked: false,
        sourceChecked: false,
        fullWidth: document.documentElement.clientWidth,
        sourceOfferIdChecked: false,
        affSubsChecked: false,
        packageChecked: false,
        countriesChecked: false,
        monthChecked: false,
        dateChecked: true,
        hourChecked: true,
        rejectReportList: [],
        total: 0,
        listLoading: false,
        historyDeductList: [],
        summariesMap: {},
        startToEndDate: '',
        timezoneOption: optionData.timezoneOption,
        sourceIdArray: [],
        affiliateIdArray: [],
        rejectReport: {
          affiliateIds: null,
          countries: null,
          pkgs: null,
          affSubs: null,
          sourceIds: null,
          sourceOfferIds: null,
          offerIds: null,
          offerName: null,
          fromDate: null,
          toDate: null,
          timezone: 0,
          columns: [],
          comparing: true,
          rejectReason: '',
          rejectValue: '',
        },
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      listenChange() {
        const {
          affiliateChecked,
          sourceChecked,
          offerChecked,
          packageChecked,
          countriesChecked,
          sourceOfferIdChecked,
          affSubsChecked,
        } = this;
        return {
          affiliateChecked,
          sourceChecked,
          offerChecked,
          packageChecked,
          countriesChecked,
          sourceOfferIdChecked,
          affSubsChecked,
        };
      },
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
      this.getSourceIdsList();
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      getRejectRate(affConversions, conversions) {
        if (affConversions && conversions) {
          return ((affConversions / (conversions + affConversions)) * 100).toFixed(2) + '%';
        } else if (affConversions) {
          return '100%';
        } else {
          return '0%';
        }
      },
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.rejectReport,
          ...this.pageParam,
        };

        if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
          param.sourceIds = param.sourceIds.join(',');
        } else {
          delete param.sourceIds;
        }
        if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
          param.affiliateIds = param.affiliateIds.join(',');
        } else {
          delete param.affiliateIds;
        }
        if (
          this.offerChecked == true &&
          param.columns.indexOf('offerId') == -1 &&
          param.columns.indexOf('offerName') == -1
        ) {
          param.columns.push('offerId');
          param.columns.push('offerName');
        } else if (
          this.offerChecked == false &&
          param.columns.indexOf('offerId') !== -1 &&
          param.columns.indexOf('offerName') !== -1
        ) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'offerId') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
          const offerNameIndex = param.columns.findIndex((item) => {
            if (item == 'offerName') {
              return true;
            }
          });
          param.columns.splice(offerNameIndex, 1);
        }

        if (this.affiliateChecked == true && param.columns.indexOf('affiliateId') == -1) {
          param.columns.push('affiliateId');
        } else if (this.affiliateChecked == false && param.columns.indexOf('affiliateId') !== -1) {
          const affiliateIndex = param.columns.findIndex((item) => {
            if (item == 'affiliateId') {
              return true;
            }
          });
          param.columns.splice(affiliateIndex, 1);
        }

        if (this.sourceChecked == true && param.columns.indexOf('sourceId') == -1) {
          param.columns.push('sourceId');
        } else if (this.sourceChecked == false && param.columns.indexOf('sourceId') !== -1) {
          const sourceIdIndex = param.columns.findIndex((item) => {
            if (item == 'sourceId') {
              return true;
            }
          });
          param.columns.splice(sourceIdIndex, 1);
        }

        if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
          param.columns.push('country');
        } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
          const countryIndex = param.columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          param.columns.splice(countryIndex, 1);
        }

        if (this.packageChecked == true && param.columns.indexOf('pkg') == -1) {
          param.columns.push('pkg');
        } else if (this.packageChecked == false && param.columns.indexOf('pkg') !== -1) {
          const prodIndex = param.columns.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          param.columns.splice(prodIndex, 1);
        }

        if (this.affSubsChecked == true && param.columns.indexOf('affSub') == -1) {
          param.columns.push('affSub');
        } else if (this.affSubsChecked == false && param.columns.indexOf('affSub') !== -1) {
          const affSubIndex = param.columns.findIndex((item) => {
            if (item == 'affSub') {
              return true;
            }
          });
          param.columns.splice(affSubIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.sourceOfferIdChecked == true && param.columns.indexOf('sourceOfferId') == -1) {
          param.columns.push('sourceOfferId');
        } else if (
          this.sourceOfferIdChecked == false &&
          param.columns.indexOf('sourceOfferId') !== -1
        ) {
          const sourceOfferIdIndex = param.columns.findIndex((item) => {
            if (item == 'sourceOfferId') {
              return true;
            }
          });
          param.columns.splice(sourceOfferIdIndex, 1);
        }

        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }

        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;
        this.getRejectReport();
      },
      getRejectReport() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getRejectReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            if (response.result.length == 0) {
              this.$message({
                message: '数据列表为空',
                type: 'warning',
              });
            }

            const summariesMap = response.result.pop() || [];
            const result = response.result || [];
            const rejectReportList = result?.map((item) => {
              if (item.affiliateTrafficTag) {
                const arr = item.affiliateTrafficTag.split('-');

                item.affColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';

                item.affiliateTrafficTag = arr[1];
              }
              if (item.affSubTrafficTag) {
                const arr = item.affSubTrafficTag.split('-');
                item.affSubColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';
                item.affSubTrafficTag = arr[1];
              }
              return item;
            });
            this.summariesMap = summariesMap;
            this.rejectReportList = rejectReportList;
            this.total = response.total;
            let totalObject = new Object();
            totalObject.date = 'Total';
            totalObject.conversions = this.summariesMap.conversions;
            totalObject.lastConversions = this.summariesMap.lastConversions;
            totalObject.affConversions = this.summariesMap.affConversions;
            totalObject.totalAffPayout = this.summariesMap.totalAffPayout;
            totalObject.lastTotalPayout = this.summariesMap.lastTotalPayout;
            totalObject.totalRevenue = this.summariesMap.totalRevenue;
            totalObject.lastTotalRevenue = this.summariesMap.lastTotalRevenue;
            totalObject.sourceOfferId = '';
            this.rejectReportList.push(totalObject);
          } else {
            this.rejectReportList = [];
            this.$message.error('Error: ' + response.message);
          }
        });
      },
      exportExcelFileClick() {
        let param = {};
        let columns = new Array();

        if (this.affiliateChecked == true && columns.indexOf('affiliateId') == -1) {
          columns.push('affiliateId');
        } else if (this.affiliateChecked == false && columns.indexOf('affiliateId') !== -1) {
          const affiliateIdIndex = columns.findIndex((item) => {
            if (item == 'affiliateId') {
              return true;
            }
          });
          columns.splice(affiliateIdIndex, 1);
        }

        if (this.affiliateChecked == true && columns.indexOf('affiliateName') == -1) {
          columns.push('affiliateName');
        } else if (this.affiliateChecked == false && columns.indexOf('affiliateName') !== -1) {
          const affiliateNameIndex = columns.findIndex((item) => {
            if (item == 'affiliateName') {
              return true;
            }
          });
          columns.splice(affiliateNameIndex, 1);
        }

        if (this.sourceChecked == true && columns.indexOf('sourceId') == -1) {
          columns.push('sourceId');
        } else if (this.sourceChecked == false && columns.indexOf('sourceId') !== -1) {
          const sourceIdIndex = columns.findIndex((item) => {
            if (item == 'sourceId') {
              return true;
            }
          });
          columns.splice(sourceIdIndex, 1);
        }

        if (this.sourceChecked == true && columns.indexOf('sourceName') == -1) {
          columns.push('sourceName');
        } else if (this.sourceChecked == false && columns.indexOf('sourceName') !== -1) {
          const sourceNameIndex = columns.findIndex((item) => {
            if (item == 'sourceName') {
              return true;
            }
          });
          columns.splice(sourceNameIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offerId') == -1) {
          columns.push('offerId');
        } else if (this.offerChecked == false && columns.indexOf('offerId') !== -1) {
          const offerIdIndex = columns.findIndex((item) => {
            if (item == 'offerId') {
              return true;
            }
          });
          columns.splice(offerIdIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offerName') == -1) {
          columns.push('offerName');
        } else if (this.offerChecked == false && columns.indexOf('offerName') !== -1) {
          const offerNameIndex = columns.findIndex((item) => {
            if (item == 'offerName') {
              return true;
            }
          });
          columns.splice(offerNameIndex, 1);
        }

        if (this.affSubsChecked == true && columns.indexOf('affSub') == -1) {
          columns.push('affSub');
        } else if (this.affSubsChecked == false && columns.indexOf('affSub') !== -1) {
          const affSubIndex = columns.findIndex((item) => {
            if (item == 'affSub') {
              return true;
            }
          });
          columns.splice(affSubIndex, 1);
        }

        if (this.sourceOfferIdChecked == true && columns.indexOf('sourceOfferId') == -1) {
          columns.push('sourceOfferId');
        } else if (this.sourceOfferIdChecked == false && columns.indexOf('sourceOfferId') !== -1) {
          const sourceOfferIdIndex = columns.findIndex((item) => {
            if (item == 'sourceOfferId') {
              return true;
            }
          });
          columns.splice(sourceOfferIdIndex, 1);
        }

        if (this.countriesChecked == true && columns.indexOf('country') == -1) {
          columns.push('country');
        } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
          const countryIndex = columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          columns.splice(countryIndex, 1);
        }

        if (this.packageChecked == true && columns.indexOf('pkg') == -1) {
          columns.push('pkg');
        } else if (this.packageChecked == false && columns.indexOf('pkg') !== -1) {
          const pkgIndex = columns.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          columns.splice(pkgIndex, 1);
        }

        if (this.monthChecked == true && columns.indexOf('month') == -1) {
          columns.push('month');
        } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
          const monthIndex = columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && columns.indexOf('date') == -1) {
          columns.push('date');
        } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
          const dateIndex = columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && columns.indexOf('hour') == -1) {
          columns.push('hour');
        } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
          const hourIndex = columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          columns.splice(hourIndex, 1);
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        if (this.rejectReport.timezone != null) {
          param['timezone'] = this.rejectReport.timezone;
        }

        if (this.rejectReport.sourceIds != null && this.rejectReport.sourceIds.length > 0) {
          param['sourceIds'] = this.rejectReport.sourceIds + '';
        }

        if (
          this.rejectReport.affiliateIds !== null &&
          this.rejectReport.affiliateIds.length !== 0
        ) {
          param['affiliateIds'] = this.rejectReport.affiliateIds + '';
        }
        if (this.rejectReport.affSubs != null) {
          param['affSubs'] = this.rejectReport.affSubs + '';
        }

        if (this.rejectReport.offerIds != null) {
          param['offerIds'] = this.rejectReport.offerIds;
        }
        if (this.rejectReport.countries != null) {
          param['countries'] = this.rejectReport.countries;
        }

        if (this.rejectReport.sourceOfferIds != null) {
          param['sourceOfferIds'] = this.rejectReport.sourceOfferIds;
        }
        if (this.rejectReport.pkgList != null) {
          param['pkgList'] = this.rejectReport.pkgList;
        }
        if (columns != null) {
          param['columns'] = columns.join(',');
        }
        if (this.rejectReport.offerName != null) {
          param['offerName'] = encodeURI(this.rejectReport.offerName);
        }
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_REJECT_REPORT}?${strParam}`, '_blank');
      },
      exportDetailClick() {
        let param = {};
        let columns = new Array();

        if (this.affiliateChecked == true && columns.indexOf('affiliateId') == -1) {
          columns.push('affiliateId');
        } else if (this.affiliateChecked == false && columns.indexOf('affiliateId') !== -1) {
          const affiliateIdIndex = columns.findIndex((item) => {
            if (item == 'affiliateId') {
              return true;
            }
          });
          columns.splice(affiliateIdIndex, 1);
        }

        if (this.affiliateChecked == true && columns.indexOf('affiliateName') == -1) {
          columns.push('affiliateName');
        } else if (this.affiliateChecked == false && columns.indexOf('affiliateName') !== -1) {
          const affiliateNameIndex = columns.findIndex((item) => {
            if (item == 'affiliateName') {
              return true;
            }
          });
          columns.splice(affiliateNameIndex, 1);
        }

        if (this.sourceChecked == true && columns.indexOf('sourceId') == -1) {
          columns.push('sourceId');
        } else if (this.sourceChecked == false && columns.indexOf('sourceId') !== -1) {
          const sourceIdIndex = columns.findIndex((item) => {
            if (item == 'sourceId') {
              return true;
            }
          });
          columns.splice(sourceIdIndex, 1);
        }

        if (this.sourceChecked == true && columns.indexOf('sourceName') == -1) {
          columns.push('sourceName');
        } else if (this.sourceChecked == false && columns.indexOf('sourceName') !== -1) {
          const sourceNameIndex = columns.findIndex((item) => {
            if (item == 'sourceName') {
              return true;
            }
          });
          columns.splice(sourceNameIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offerId') == -1) {
          columns.push('offerId');
        } else if (this.offerChecked == false && columns.indexOf('offerId') !== -1) {
          const offerIdIndex = columns.findIndex((item) => {
            if (item == 'offerId') {
              return true;
            }
          });
          columns.splice(offerIdIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offerName') == -1) {
          columns.push('offerName');
        } else if (this.offerChecked == false && columns.indexOf('offerName') !== -1) {
          const offerNameIndex = columns.findIndex((item) => {
            if (item == 'offerName') {
              return true;
            }
          });
          columns.splice(offerNameIndex, 1);
        }

        if (this.affSubsChecked == true && columns.indexOf('affSub') == -1) {
          columns.push('affSub');
        } else if (this.affSubsChecked == false && columns.indexOf('affSub') !== -1) {
          const affSubIndex = columns.findIndex((item) => {
            if (item == 'affSub') {
              return true;
            }
          });
          columns.splice(affSubIndex, 1);
        }

        if (this.sourceOfferIdChecked == true && columns.indexOf('sourceOfferId') == -1) {
          columns.push('sourceOfferId');
        } else if (this.sourceOfferIdChecked == false && columns.indexOf('sourceOfferId') !== -1) {
          const sourceOfferIdIndex = columns.findIndex((item) => {
            if (item == 'sourceOfferId') {
              return true;
            }
          });
          columns.splice(sourceOfferIdIndex, 1);
        }

        if (this.countriesChecked == true && columns.indexOf('country') == -1) {
          columns.push('country');
        } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
          const countryIndex = columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          columns.splice(countryIndex, 1);
        }

        if (this.packageChecked == true && columns.indexOf('pkg') == -1) {
          columns.push('pkg');
        } else if (this.packageChecked == false && columns.indexOf('pkg') !== -1) {
          const pkgIndex = columns.findIndex((item) => {
            if (item == 'pkg') {
              return true;
            }
          });
          columns.splice(pkgIndex, 1);
        }

        if (this.monthChecked == true && columns.indexOf('month') == -1) {
          columns.push('month');
        } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
          const monthIndex = columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && columns.indexOf('date') == -1) {
          columns.push('date');
        } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
          const dateIndex = columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && columns.indexOf('hour') == -1) {
          columns.push('hour');
        } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
          const hourIndex = columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          columns.splice(hourIndex, 1);
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        if (this.rejectReport.timezone != null) {
          param['timezone'] = this.rejectReport.timezone;
        }

        if (this.rejectReport.sourceIds != null && this.rejectReport.sourceIds.length > 0) {
          param['sourceIds'] = this.rejectReport.sourceIds + '';
        }

        if (
          this.rejectReport.affiliateIds !== null &&
          this.rejectReport.affiliateIds.length !== 0
        ) {
          param['affiliateIds'] = this.rejectReport.affiliateIds + '';
        }
        if (this.rejectReport.affSubs != null) {
          param['affSubs'] = this.rejectReport.affSubs + '';
        }

        if (this.rejectReport.offerIds != null) {
          param['offerIds'] = this.rejectReport.offerIds;
        }
        if (this.rejectReport.countries != null) {
          param['countries'] = this.rejectReport.countries;
        }

        if (this.rejectReport.sourceOfferIds != null) {
          param['sourceOfferIds'] = this.rejectReport.sourceOfferIds;
        }
        if (this.rejectReport.pkgList != null) {
          param['pkgList'] = this.rejectReport.pkgList;
        }
        if (columns != null) {
          param['columns'] = columns.join(',');
        }
        if (this.rejectReport.offerName != null) {
          param['offerName'] = encodeURI(this.rejectReport.offerName);
        }
        param.detailDownLoadFlag = true;
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_REJECT_REPORT_DETAIL}?${strParam}`, '_blank');
      },
      affiliatetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      showReportComparingItem(cur, last) {
        const pecent =
          cur == last
            ? '--'
            : 0 == last
            ? '100%'
            : new Number(Math.round(((cur - last) / last) * 100)) + '%';
        const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
        let result = cur;
        if (last != 0) {
          result += '<span style="color:' + color + '">(' + pecent + ')</span>';
        }
        return result;
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getRejectReport();
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
